<template>
  <div id="school-standards">
      <b-container class="base-container-x">
        <div v-if="loading === true" class="col-12">
          <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
        </div>
        <div v-if="loading === false" id="banner" class="banner-inner gys">
          <!-- <div class="slideshow_caption">
            <div class="slideshow_inner_caption">
              <div class="slideshow_align_caption">
                <h1 class="section-header-title text-uppercase">{{ $t('EYEP') }}</h1>
                <h3 class="article-content">{{ $t('Exchange Yoga Experience Program (WYA Continuous Yoga Education)') }}</h3>
              </div>
            </div>
          </div> -->
        </div>
        <div v-if="loading === true" class="col-12 mt-4">
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
          <br/>
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
        </div>
        <div v-if="loading === false" class="col-12 mt-4 mb-5 pt-4">
          <div class="d-none d-lg-flex d-xl-flex justify-content-center align-items-center">
            <h2 class="text-center font-weight-bold">{{ $t('Genuine Yoga Studio – GYS') }}</h2>
            <b-img width="100%" class="ml-5" :src="PUBLIC_PATH+'img/certificate/GYS.png'"/>
          </div>
          <div class="d-block d-lg-none d-xl-none">
            <h2 class="text-center font-weight-bold">{{ $t('Genuine Yoga Studio – GYS') }}</h2>
            <div class="d-flex justify-content-center align-items-center">
              <b-img width="100%" class="mt-3" :src="PUBLIC_PATH+'img/certificate/GYS.png'"/>
            </div>
          </div>
          <div class="introduction">
            <h3 class="mt-5 mb-3">{{ $t('Introduction:') }}</h3>
            <p>{{ $t('World Yoga Alliance values quality over quantity. This is why we love to support local yoga studios. Here WYA Community provides you a chance to demonstrate to the public that your studio has met the highest standard of facilities and processes available. Use this membership to promote your studio space, workshop, retreats and events to the local public.') }}</p>
            <p>{{ $t('If you are looking for yoga studio registration, we are waiting for you to join the community. Please select your registration type Join WYA as a Yoga Studio.') }} {{ $t('If you need assistance, please feel free to') }} <router-link :to="{ name: 'contact' }">{{  $t('contact us')}}.</router-link></p>
          </div>
          <div>
            <h3>✔️ {{ $t('The Benefits for Each Studio Membership:') }}</h3>
            <ul>
              <li>{{ $t('Promote your workshops & events to thousands of yoga teachers & students.') }}</li>
              <li>{{ $t('Worldwide Accreditation') }}</li>
              <li>{{ $t('Get listed on WYA International Yoga Directory.') }}</li>
              <li>{{ $t('Permission to use WYA official logo for Genuine Yoga Studio (GYS) ') }}</li>
              <li>{{ $t('Get your own Public Studio profile in WYA Website.') }}</li>
              <li>{{ $t('Promote your training/events via WYA website & social media Channels.') }}</li>
              <li>{{ $t('Access to WYA online community') }}</li>
              <li>{{ $t('Access to WYA exclusive educational center') }}</li>
              <li>{{ $t('Receive up to 40% discount for joining WYA member park where you can promote your yoga products online.') }}</li>
              <li>{{ $t('An opportunity to become WYA Certified Yoga School.') }}</li>
              <p>{{  $t('(If Applicant pass the requirements and standards for CYS) ') }}</p>
            </ul>
          </div>
          <div>
            <h3>{{  $t('The Criteria') }}</h3>
            <p>{{ $t('Before we can accredit your studio as a WYA Partner we do need to ensure you meet the following requirements:') }}</p>
            <h5>{{ $t('Requirements for Applicant Yoga Studios:') }}</h5>
            <ol>
              <li>{{ $t('Have a reception area.') }}</li>
              <li>{{ $t('Have changing areas and toilets') }}</li>
              <li>{{ $t('Have mats available (free to use) for guests/students or members.') }}</li>
              <li>{{ $t('Studio Owner or the main teacher must be completed 200-hour yoga teacher training. ') }}</li>
              <li>{{ $t('Studio must be available for rents. (few hours for creating an event or workshop)') }}</li>
              <li>{{ $t('Have a class scheduling system or timetable.') }}</li>
              <li>{{ $t('Must be small space available for WYA Logo or x-stand. ') }}</li>
              <li>{{ $t('Studio must follow the Safety precautions for Covid-19 by local government guidelines.') }}</li>
              <li>{{ $t('Studio must agree to follow our terms and conditions / Ethical guideline as a member of World Yoga Alliance Community.') }}</li>
            </ol>
            <h5>{{ $t('The Registration Fee is Annual:') }}</h5>
            <ul>
              <li>{{ $t('49 USD / 41 EUR / 1,599 THB / 3,999 INR') }}</li>
            </ul>
          </div>
        </div>
      </b-container>
  </div>
</template>

<script>
export default {
  name: 'ChildrenYogaSchool',
  title: ' | Children\'s Yoga School',
  data () {
    return {
      loading: false
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    scrollToElement (id) {
      const element = document.getElementById(id)
      const elementOffset = element.offsetTop - 80
      console.log('elementOffset', elementOffset)
      window.scroll({ top: elementOffset, behavior: 'smooth' })
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
 #school-standards {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .levels-table td:hover,
  .introduction li:hover,
  .introduction strong:hover {
    cursor:pointer;
  }
</style>
